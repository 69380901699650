import React , { useState, useCallback }from 'react';
import { Link } from 'react-router-dom';
import ImageViewer from 'react-simple-image-viewer';
function CardItem(props) {
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };


  return (
    <>
      <li className='cards__item'>
        <div className='cards__item__link'>
          <figure className='cards__item__pic-wrap' data-category={props.label}>
            <img
              className='cards__item__img'
              alt='Travel Image'
              src={props.src}
              onClick={ () => openImageViewer(0) }
            />
          </figure>
          <div className='cards__item__info'>
            {/*<h5 className='cards__item__text'>{props.text}</h5>*/}
            {typeof (props.text) === "object"  ?
                <h5 className='cards__item__text'>
                  <ul>
                    {props.text.map((item)=> <li key={item}>{item}</li>)}
                  </ul>
                </h5>
                : <h5 className='cards__item__text'>{props.text}</h5>
            }

          </div>
        </div>
      </li>
      {isViewerOpen && (
          <ImageViewer
              src={ props.images }
              currentIndex={ currentImage }
              disableScroll={ false }
              closeOnClickOutside={ true }
              onClose={ closeImageViewer }
          />
      )}
    </>
  );
}

export default CardItem;
