import React from 'react';
import '../../App.css';
import Cards from "../Cards";
import Footer from "../Footer";

function Projects() {
    return(
        <>
            <h1 className='products'>Projects</h1>;
            <Cards />
            <Footer />
        </>
    );
}
export default Projects;