import React from 'react';
import './Cards.css';
import CardItem from './CardItem';

function Cards() {
    return (
        <div className='cards'>
            <h1>項目</h1>
            <div className='cards__container'>
                <div className='cards__wrapper'>
                    <ul className='cards__items'>
                        <CardItem
                            src='images/漾日居/IMG_index.png'
                            text={['實用面積:約1100尺']}
                            label='漾日居'
                            // path='/services'
                            images={["images/漾日居/IMG_index.png", "images/漾日居/1.png", "images/漾日居/10.png", "images/漾日居/11.png", "images/漾日居/12.png"
                                , "images/漾日居/13.png", "images/漾日居/14.png", "images/漾日居/15.png", "images/漾日居/16.png", "images/漾日居/17.png", "images/漾日居/18.png",
                                "images/漾日居/19.png", "images/漾日居/2.png", "images/漾日居/20.png", "images/漾日居/21.png", "images/漾日居/22.png",
                                "images/漾日居/23.png", "images/漾日居/24.png", "images/漾日居/25.png", "images/漾日居/26.png", "images/漾日居/27.png",
                                "images/漾日居/28.png", "images/漾日居/3.png", "images/漾日居/4.png", "images/漾日居/5.png", "images/漾日居/6.png",
                                "images/漾日居/7.png", "images/漾日居/8.png", "images/漾日居/9.png"
                            ]}
                        />
                        <CardItem
                            src='images/西貢/IMG_index.png'
                            text='實用面積:約1500尺'
                            label='西貢獨立屋'
                            // path='/services'
                            images={["images/西貢/IMG_index.png","images/西貢/1.png","images/西貢/10.png","images/西貢/11.png","images/西貢/12.png","images/西貢/13.png","images/西貢/2.png","images/西貢/3.png",
                                "images/西貢/4.png","images/西貢/5.png","images/西貢/6.png","images/西貢/7.png","images/西貢/8.png","images/西貢/9.png"]}
                        />
                    </ul>
                    <ul className='cards__items'>
                        <CardItem
                            src='images/爾巒/IMG_index.png'
                            text='實用面積:約2100尺'
                            label='爾巒'
                            // path='/services'
                            images={["images/爾巒/IMG_index.png","images/爾巒/1.png","images/爾巒/10.png","images/爾巒/11.png","images/爾巒/12.png","images/爾巒/13.png","images/爾巒/14.png","images/爾巒/15.png","images/爾巒/16.png","images/爾巒/17.png","images/爾巒/18.png","images/爾巒/19.png","images/爾巒/2.png","images/爾巒/20.png","images/爾巒/21.png","images/爾巒/22.png","images/爾巒/23.png","images/爾巒/24.png","images/爾巒/25.png","images/爾巒/26.png","images/爾巒/27.png","images/爾巒/28.png","images/爾巒/29.png","images/爾巒/3.png","images/爾巒/4.png"
                                ,"images/爾巒/5.png","images/爾巒/6.png","images/爾巒/7.png","images/爾巒/8.png","images/爾巒/9.png"]}
                        />
                        <CardItem
                            src='images/昇柏山/IMG_index.png'
                            text='實用面積:約750尺'
                            label='昇柏山'
                            // path='/products'
                            images={["images/昇柏山/IMG_index.png","images/昇柏山/1.png","images/昇柏山/10.png","images/昇柏山/11.png","images/昇柏山/12.png","images/昇柏山/13.png","images/昇柏山/14.png","images/昇柏山/15.png","images/昇柏山/16.png","images/昇柏山/17.png","images/昇柏山/18.png","images/昇柏山/19.png","images/昇柏山/20.png","images/昇柏山/3.png","images/昇柏山/4.png"
                                ,"images/昇柏山/5.png","images/昇柏山/6.png","images/昇柏山/7.png","images/昇柏山/8.png","images/昇柏山/9.png"]}
                        />
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default Cards;
